import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { getImgixUrl } from '../../../../../../helpers';

const LightBoxImageGallery = loadable(() =>
  import(
    /* webpackChunkName: "LightBoxImageGallery" */ './LightBoxGalleryModule'
  ),
);

export const LightBoxGallery = ({
  imageIndex,
  isOpen,
  imageGalleryList,
  setImageIndex,
  setIsOpen,
  displayImageCaption,
  pageConfig: { tenantId },
}) => {
  const imageList = imageGalleryList.map(({ image: { path }, alt }) => {
    return {
      title: alt,
      url: getImgixUrl(tenantId, path),
    };
  });

  const nextImageIndex = (imageIndex + 1) % imageList.length;
  const prevImageIndex = (imageIndex + imageList.length - 1) % imageList.length;

  return (
    isOpen && (
      <div data-testid="light-box-image-gallery-wrapper">
        <LightBoxImageGallery
          reactModalStyle={{ overlay: { zIndex: 9999 } }}
          mainSrc={imageList[imageIndex]?.url}
          nextSrc={imageList[nextImageIndex]?.url}
          prevSrc={imageList[prevImageIndex]?.url}
          imageCaption={displayImageCaption && imageList[imageIndex]?.title}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setImageIndex(prevImageIndex)}
          onMoveNextRequest={() => setImageIndex(nextImageIndex)}
        />
      </div>
    )
  );
};

LightBoxGallery.propTypes = {
  imageIndex: PropTypes.number,
  isOpen: PropTypes.bool,
  imageGalleryList: PropTypes.arrayOf(PropTypes.object),
  setImageIndex: PropTypes.func,
  setIsOpen: PropTypes.func,
  displayImageCaption: PropTypes.bool,
  pageConfig: PropTypes.shape({
    tenantId: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  pageConfig: state.pageConfig,
});

export default connect(mapStateToProps)(LightBoxGallery);
