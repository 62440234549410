import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { renderStyledElementStyles } from '../../../../helpers';

import CallToAction from '../../../../../shared/CallToAction/CallToAction';
import { ImageGallery } from '../../innerComponents';
import { Overlay, Title, Offset } from '../../styles';

const Wrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements.gallery)}

  &:hover {
    ${Title} {
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.dStyles.elements.gallery.elements?.title?.hoverSettings,
        )}
    }

    ${Overlay} {
      opacity: 1;
    }
  }
`;

export function ImageGalleryProfile(props) {
  const {
    imageGallery: { title, link, link: { shown: isLink } = {} },
    lightboxEnabled,
    imageIndex,
    setImageIndex,
    setIsOpen,
  } = props;

  const handleImageClick = useCallback(() => {
    setImageIndex(imageIndex);
    setIsOpen(true);
  }, [imageIndex, setImageIndex, setIsOpen]);

  return (
    <Wrapper>
      <div className="embed-responsive embed-responsive-1by1">
        <div className="embed-responsive-item">
          <ImageGallery
            imageGallery={props.imageGallery}
            handleClick={lightboxEnabled ? handleImageClick : undefined}
            lightboxEnabled={lightboxEnabled}
          >
            <Overlay />
          </ImageGallery>
        </div>
      </div>
      {(isLink || title) && <Offset />}
      {isLink && (
        <CallToAction type="link" ctaConfig={link}>
          <Title>{title}</Title>
        </CallToAction>
      )}
      {!isLink && title && <Title>{title}</Title>}
    </Wrapper>
  );
}

ImageGalleryProfile.propTypes = {
  imageGallery: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.shape({
      shown: PropTypes.bool,
    }),
  }),
  lightboxEnabled: PropTypes.bool,
  imageIndex: PropTypes.number,
  setImageIndex: PropTypes.func,
  setIsOpen: PropTypes.func,
};
