import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { devices } from '../../../../helpers';

import { ImageGallery } from '../../innerComponents';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media only screen and (min-width: ${devices.breakpoints.tablet}) {
    flex-direction: row;
  }
`;

export const ImageGalleryResponsive = ({
  imageGalleryList,
  lightboxEnabled,
  setImageIndex,
  setIsOpen,
}) => {
  return (
    <div data-testid="three-columns">
      <div className="hidden-xs">
        <div className="embed-responsive embed-responsive-4by1">
          <div className="embed-responsive-item">
            <Wrapper>
              {imageGalleryList.map((imageGallery, index) => (
                <ImageGallery
                  key={index}
                  imageGallery={imageGallery}
                  lightboxEnabled={lightboxEnabled}
                  handleClick={
                    lightboxEnabled
                      ? () => {
                          setImageIndex(index);
                          setIsOpen(true);
                        }
                      : undefined
                  }
                />
              ))}
            </Wrapper>
          </div>
        </div>
      </div>
      <div className="visible-xs">
        <Wrapper>
          {imageGalleryList.map((imageGallery, index) => (
            <div key={index} className="embed-responsive embed-responsive-4by3">
              <div className="embed-responsive-item">
                <ImageGallery
                  lightboxEnabled={lightboxEnabled}
                  imageGallery={imageGallery}
                  onClick={
                    lightboxEnabled
                      ? () => {
                          setImageIndex(index);
                          setIsOpen(true);
                        }
                      : undefined
                  }
                />
              </div>
            </div>
          ))}
        </Wrapper>
      </div>
    </div>
  );
};

ImageGalleryResponsive.propTypes = {
  imageGalleryList: PropTypes.arrayOf(PropTypes.object),
  lightboxEnabled: PropTypes.bool,
  setImageIndex: PropTypes.func,
  setIsOpen: PropTypes.func,
};
