import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';

import { renderStyledElementStyles } from '../../../../helpers';

import { AlignMiddleHelper } from '../../styles';
import { CallToAction, ImageTag } from '../../../sharedComponents';

const GalleryWrapper = styled.div`
  display: block;
  height: 100%;
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements.gallery.elements?.galleryWrapper,
    )}
`;

const ImageWrapper = styled.div`
  display: block;
  height: 100%;
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements.gallery.elements?.imageWrapper,
    )}
  img {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements.gallery.elements?.image,
      )}
  }
`;

export function ImageGallery(props) {
  const {
    imageGallery: {
      alt,
      image: { path },
      link: { shown: isLink } = {},
      link,
    },
    handleClick,
    lightboxEnabled,
    children,
  } = props;
  const themeConfig = useContext(ThemeContext);
  const imgixParams =
    themeConfig.module.dStyles.elements.gallery.elements?.image?.settings
      ?.imgixParams;

  const imageComponent = (
    <>
      <AlignMiddleHelper />
      <ImageTag src={path} alt={alt} imgixParams={imgixParams} />
      {children}
    </>
  );

  return (
    <GalleryWrapper onClick={handleClick} data-testid="gallery-wrapper">
      {isLink && !lightboxEnabled ? (
        <ImageWrapper data-testid="gallery-with-link">
          <CallToAction type="link" ctaConfig={link}>
            {imageComponent}
          </CallToAction>
        </ImageWrapper>
      ) : (
        <ImageWrapper data-testid="gallery-without-link">
          {imageComponent}
        </ImageWrapper>
      )}
    </GalleryWrapper>
  );
}

ImageGallery.propTypes = {
  imageGallery: PropTypes.shape({
    alt: PropTypes.string,
    link: PropTypes.shape({
      shown: PropTypes.bool,
      path: PropTypes.string,
    }),
    image: PropTypes.shape({
      path: PropTypes.string,
    }),
  }).isRequired,
  lightboxEnabled: PropTypes.bool,
};
