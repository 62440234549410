import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../helpers';

export const Title = styled.h4`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements.gallery.elements?.title,
    )}
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  opacity: 0;
  transition: opacity 0.3s ease;
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements.gallery.elements?.overlay,
    )}
`;

export const Offset = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements.gallery.elements?.offset,
    )}
`;

export const AlignMiddleHelper = styled.span`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements.gallery.elements?.alignMiddleHelper,
    )}
`;
