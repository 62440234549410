import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { renderStyledElementStyles } from '../../../../helpers';

import { ImageGallery } from '../../innerComponents';

const Wrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements.gallery)}
`;

export function ImageGalleryList({
  imageGallery,
  lightboxEnabled,
  imageIndex,
  setImageIndex,
  setIsOpen,
}) {
  const handleImageClick = useCallback(() => {
    setImageIndex(imageIndex);
    setIsOpen(true);
  }, [imageIndex, setImageIndex, setIsOpen]);

  return (
    <Wrapper>
      <div className="embed-responsive embed-responsive-1by1">
        <div className="embed-responsive-item">
          <ImageGallery
            imageGallery={imageGallery}
            handleClick={lightboxEnabled ? handleImageClick : undefined}
            lightboxEnabled={lightboxEnabled}
          />
        </div>
      </div>
    </Wrapper>
  );
}

ImageGalleryList.propTypes = {
  imageGallery: PropTypes.object.isRequired,
  lightboxEnabled: PropTypes.bool,
  imageIndex: PropTypes.number,
  setImageIndex: PropTypes.func,
  setIsOpen: PropTypes.func,
};
