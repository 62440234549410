import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';

import { renderStyledElementStyles } from '../../helpers';
import { THREE_COLUMNS, FOUR_COLUMNS, SIX_COLUMNS } from './constants';

import {
  ImageGalleryResponsive,
  ImageGalleryProfile,
  ImageGalleryList,
} from './displayStyles';
import {
  SiteBuilderModule,
  StyledCallToAction,
  StyledHeading,
  CallToActionConfigProps,
} from '../sharedComponents';
import LightBoxGallery from './innerComponents/LightBoxGallery';

const Wrapper = styled.div`
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.dStyles)}
`;

const ImageGalleryModule = (props) => {
  const {
    section: {
      entries = [],
      imageGalleryTitle,
      titleSize,
      imageGalleryLayout: galleryDisplayStyle = THREE_COLUMNS,
      link,
      lightboxEnabled,
      displayImageCaption,
    } = {},
  } = props;
  const imageGalleryList = entries.filter((entry) => entry?.shown);
  const themeConfig = useContext(ThemeContext);

  const [imageIndex, setImageIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  if (imageGalleryList.length === 0) {
    return null;
  }

  const dStyles =
    themeConfig.siteBuilderModules.imageGalleryModule.displayStyles[
      galleryDisplayStyle
    ];
  const IS_THREE_COLUMNS = galleryDisplayStyle === THREE_COLUMNS;
  const IS_FOUR_COLUMNS = galleryDisplayStyle === FOUR_COLUMNS;
  const IS_SIX_COLUMNS = galleryDisplayStyle === SIX_COLUMNS;

  return (
    <SiteBuilderModule moduleTheme={{ dStyles }} section={props.section}>
      <Wrapper data-testid="image-gallery-module">
        {IS_THREE_COLUMNS && (
          <ImageGalleryResponsive
            imageGalleryList={imageGalleryList}
            lightboxEnabled={lightboxEnabled}
            setImageIndex={setImageIndex}
            setIsOpen={setIsOpen}
          />
        )}
        {(IS_FOUR_COLUMNS || IS_SIX_COLUMNS) && (
          <div className="container">
            {imageGalleryTitle && (
              <div className="row">
                <div className="col-xs-12">
                  <StyledHeading
                    content={imageGalleryTitle}
                    as={titleSize}
                    displayStyles={dStyles}
                  />
                </div>
              </div>
            )}

            {IS_FOUR_COLUMNS && (
              <div className="row" data-testid="four-columns">
                {imageGalleryList.map((imageGallery, index) => (
                  <div key={index} className="col-xs-6 col-sm-3 col-md-3">
                    <ImageGalleryProfile
                      imageGallery={imageGallery}
                      lightboxEnabled={lightboxEnabled}
                      imageIndex={index}
                      setImageIndex={setImageIndex}
                      setIsOpen={setIsOpen}
                    />
                  </div>
                ))}
              </div>
            )}

            {IS_SIX_COLUMNS && (
              <div
                className="row"
                style={{ justifyContent: 'center' }}
                data-testid="six-columns"
              >
                {imageGalleryList.map((imageGallery, index) => (
                  <div key={index} className="col-xs-6 col-sm-3 col-md-2">
                    <ImageGalleryList
                      imageGallery={imageGallery}
                      lightboxEnabled={lightboxEnabled}
                      imageIndex={index}
                      setImageIndex={setImageIndex}
                      setIsOpen={setIsOpen}
                      data-testid="image-gallery-list"
                    />
                  </div>
                ))}
              </div>
            )}

            {link?.type && (
              <div className="row" data-testid="image-gallery-cta">
                <div className="col-xs-12">
                  <StyledCallToAction
                    mode="black"
                    ctaConfig={link}
                    displayStyles={dStyles}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {lightboxEnabled && (
          <LightBoxGallery
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setImageIndex={setImageIndex}
            imageGalleryList={imageGalleryList}
            imageIndex={imageIndex}
            displayImageCaption={displayImageCaption}
          />
        )}
      </Wrapper>
    </SiteBuilderModule>
  );
};

ImageGalleryModule.propTypes = {
  section: PropTypes.shape({
    entries: PropTypes.arrayOf(PropTypes.object),
    imageGalleryTitle: PropTypes.string,
    titleSize: PropTypes.string,
    imageGalleryLayout: PropTypes.oneOf([
      THREE_COLUMNS,
      FOUR_COLUMNS,
      SIX_COLUMNS,
    ]),
    link: CallToActionConfigProps,
    lightboxEnabled: PropTypes.bool,
    displayImageCaption: PropTypes.bool,
  }).isRequired,
};

export default ImageGalleryModule;
